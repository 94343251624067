"use client";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { SavingsTracker } from "src/employee/portal/components/SavingsTracker";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export interface PhoneGreenProps {
  className?: string;
}

export default function PhoneGreen({ className }: PhoneGreenProps) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(useGSAP);
  const phoneEl = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.fromTo(
      phoneEl.current,
      {
        marginTop: 50,
        opacity: 0,
        willChange: "margin-top",
      },
      {
        marginTop: 0,
        opacity: 1,
        ease: "power3.out",
        willChange: "margin-top",
        duration: 1,
        onComplete: () => {
          gsap.to(phoneEl.current, {
            marginTop: -200,
            ease: "power3.out",
            scrollTrigger: {
              scrub: 1,
            },
          });
        },
      },
    );
  });

  const totalInSavings = 100;
  const totalProcessing = 185;
  const totalInRewards = 50;
  const maxAnnualSavings = 1000;

  return (
    <div className={className} suppressHydrationWarning ref={phoneEl}>
      <img src="/img/phone-green.png" alt="phone image" className="w-full" />
      <SavingsTracker
        maxAnnualSavings={maxAnnualSavings}
        totalInRewards={totalInRewards}
        totalProcessing={totalProcessing}
        totalInSavings={totalInSavings}
        opened
        className="!absolute top-0 z-1 pointer-events-none top-[5%] !w-[180%] sm:top-[11%] -ml-[40%] scale-[0.45] md:!w-[120%] md:-ml-[10%] md:scale-[0.7] md:top-[15%] lg:ml-auto lg:top-[18%] lg:scale-[0.85] lg:!w-auto"
      />
    </div>
  );
}
