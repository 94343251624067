export function formatCurrency(
  value: number,
  options?: {
    currency?: "USD";
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    style?: "currency" | "decimal";
  },
): string {
  const formatter = new Intl.NumberFormat("en-US", {
    currency: options?.currency ?? "USD",
    maximumFractionDigits: options?.maximumFractionDigits ?? 2,
    minimumFractionDigits: options?.minimumFractionDigits ?? 2,
    style: options?.style ?? "currency",
  });

  return formatter.format(value);
}
