import { ReactNode } from "react";
import { CSSRuleObject } from "tailwindcss/types/config";

export interface ParagraphProps {
  as?: "p" | "div" | "small";
  className?: string;
  children: ReactNode;
  role?: "alert";
  style?: CSSRuleObject;
  testId?: string;
}

export function Paragraph({
  as,
  children,
  className,
  role,
  style,
  testId,
}: ParagraphProps) {
  let Element = as ?? "p";

  const leading = className?.includes("leading") ? "" : "leading-snug";
  const fontWeight = className?.includes("font-") ? "" : "font-[500]";
  const maxWidth = className?.includes("max-w-") ? "" : "max-w-prose";

  return (
    <Element
      className={`font-sans ${fontWeight} ${leading} ${maxWidth} tracking-[-.0125em] ${
        className ?? ""
      }`}
      role={role}
      style={style}
      data-testid={testId}
    >
      {children}
    </Element>
  );
}
