import { animated, useSpring } from "@react-spring/web";
import { ReactNode, useEffect, useRef, useState } from "react";

export interface AccordionProps {
  children: ReactNode;
  isOpen: boolean;
  onRest?: () => void;
  className?: string;
  startHeight?: number;
}

export function Accordion({
  children,
  isOpen,
  onRest,
  startHeight = 0,
  className = "",
}: AccordionProps) {
  const [height, setHeight] = useState<number>(startHeight);
  const ref = useRef<HTMLDivElement>(null);

  const { maxHeight } = useSpring({
    maxHeight: isOpen ? height ?? "auto" : startHeight,
    onRest,
  });

  useEffect(() => {
    const element = ref?.current;

    if (!ResizeObserver || !element) return;

    const observer = new ResizeObserver(() => {
      setHeight(element?.offsetHeight ?? startHeight);
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, [startHeight]);

  return (
    <animated.div
      style={{ maxHeight: maxHeight.to((value) => `${value}px`) }}
      className={`block overflow-clip overflow-hidden ${className}`}
    >
      <div ref={ref}>{children}</div>
    </animated.div>
  );
}
