"use client";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from "gsap";

export function Stars() {
  const ref = useRef<SVGSVGElement>(null);

  useGSAP(() => {
    gsap.set(ref.current!.children, {
      transformOrigin: "center center",
    });
    gsap.to(ref.current!.children, {
      rotation: Math.random() > 0.5 ? 360 : -360,
      ease: "none",
      duration: 7 + Math.random() * 2,
      repeat: -1,
    });
  }, [ref]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="53"
      viewBox="0 0 95 53"
      fill="none"
      ref={ref}
    >
      <path
        d="M90.2478 45.7769L89.3816 42.1932L87.1674 45.1388L83.5846 46.0005L86.5302 48.2147L87.3919 51.7975L89.6106 48.8528L93.1889 47.9902L90.2478 45.7769Z"
        fill="#F8D120"
      />
      <path
        d="M16.5583 28.7439L12.5499 20.2489L8.54163 28.7439L0.0466309 32.7522L8.54163 36.7606L12.5499 45.2721L16.5583 36.7606L25.0533 32.7522L16.5583 28.7439Z"
        fill="#F8D120"
      />
      <path
        d="M63.4505 8.71041L58.1078 3.70598L57.6424 11.0077L52.6346 16.342L59.9363 16.8074L65.2706 21.8152L65.7445 14.5101L70.7438 9.1792L63.4505 8.71041Z"
        fill="#F8D120"
      />
    </svg>
  );
}
